<template>
  <div class="expertiseStage" v-if="data ">
      <img src="./../../assets/images/mountain-1.png" alt="Nunatak Mountains" />
      <div class="wrapper">
        <h1 v-html="nl2br(data[0].headline)"></h1>
      </div>
 </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ExpertiseStage',
  props: ['data', 'post'],
  mounted() {
    console.log(this.data)
  },
  computed: {
    ...mapState({
      language: state => state.language.language
    }),
  },
  methods: {
    nl2br(str, is_xhtml) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }
      const breakTag =
          is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
      return (str + "").replace(
          /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
          "$1" + breakTag + "$2"
      );
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/config.scss';
.expertiseStage {
  margin: 0 auto 100px;
  position: relative;
  padding: 150px 0;
  overflow: hidden;
  @media screen and (max-width: $medium) {
    padding: 150px 0;
    margin: 0 auto 50px
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 80px 30%;
    @media screen and (max-width: $medium) {
      object-position: 50% 30%;
    }
  }

  h1 {
    position: relative;
    z-index: 1;
    font-size: 45px;
    line-height: 47px;
    color: $primary;
    background: transparent;
    padding: 30px 0;
    font-weight: 300;
    display: inline-block;
  }
}
</style>