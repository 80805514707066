<template>
  <div class="wrapper ">
    <div class="experts">
      <h4 v-html="nl2br(data[0].headline)"></h4>
      <div class="expertsList">
        <div class="expert" v-for="(member,key) in experts" :key="key">
          <figure>

            <div class="imageMask">
              <div class="socialIcons" v-if="member.acf.team_member.social_channels">
                <ul>
                  <li v-if="member.acf.team_member.email">
                    <!-- a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'linkedin'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M289 273.6h-35.4v-55.4c0-13.2-.2-30.2-18.4-30.2-18.4 0-21.2 14.4-21.2 29.2v56.3h-35.4V159.7h33.9v15.6h.5c6.9-11.8 19.8-18.9 33.5-18.4 35.8 0 42.4 23.6 42.4 54.2l.1 62.5zM138.7 144.2c-11.3 0-20.5-9.2-20.5-20.5s9.2-20.5 20.5-20.5 20.5 9.2 20.5 20.5c.1 11.3-9.1 20.5-20.5 20.5m17.7 129.4H121V159.7h35.4v113.9z" fill="#4b4b4d"/></svg></a -->
                    <a target="_blank" href="" @click.prevent="mailTo(encodeEmail(member.acf.team_member.email))">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
                        <path
                            d="M63.7 183.3l44 17.7 30 87.9 44.9-41.9 64.4 47c7.5 5.5 18.2 2.1 21.1-6.8l58.7-176.8c2.3-6.8-3.8-13.4-10.8-11.8L65.7 157.2c-13 3.1-14.4 21.1-2 26.1zm76 31.2l-6.5 35.6-18.6-51.3 173-77.2-147.9 92.9z"
                            fill="#4b4b4d"/>
                      </svg>
                    </a>
                    <!-- a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'twitter'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M164.3 287.2c78.3 0 121.2-64.9 121.2-121.2 0-1.8 0-3.7-.1-5.5 8.3-6 15.5-13.5 21.3-22.1-7.6 3.4-15.9 5.6-24.5 6.7 8.8-5.2 15.5-13.6 18.8-23.6-8.2 4.9-17.4 8.4-27.1 10.3-7.8-8.3-18.9-13.5-31.1-13.5-23.5 0-42.6 19.1-42.6 42.6 0 3.3.4 6.6 1.1 9.7-35.4-1.7-66.8-18.8-87.8-44.5-3.7 6.3-5.7 13.6-5.7 21.4 0 14.8 7.6 27.8 18.9 35.5-7-.2-13.5-2.2-19.3-5.3v.6c0 20.6 14.7 37.9 34.1 41.8-3.6 1-7.3 1.5-11.2 1.5-2.7 0-5.4-.2-8-.7 5.4 16.9 21.2 29.2 39.8 29.6-14.6 11.5-33 18.3-52.9 18.3-3.4 0-6.8-.2-10.1-.6 18.7 11.9 41.2 19 65.2 19" fill="#4b4b4d"/></svg></a -->
                    <!-- a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'xing'"><svg version="1.1" id="White" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 400 400" xml:space="preserve"><path class="st0" d="M123.1 135.6c-1.8 0-3.3.6-4 1.8-.8 1.3-.7 2.9.2 4.5l20 34.6v.2l-31.4 55.4c-.8 1.6-.8 3.3 0 4.5s2.1 2 3.9 2h29.5c4.4 0 6.5-3 8.1-5.7 0 0 30.7-54.3 31.9-56.4-.1-.2-20.3-35.4-20.3-35.4-1.5-2.6-3.7-5.5-8.2-5.5h-29.7zM247.2 95.2c-4.4 0-6.3 2.8-7.9 5.6 0 0-63.6 112.8-65.7 116.6.1.2 42 77 42 77 1.5 2.6 3.7 5.6 8.3 5.6h29.5c1.8 0 3.2-.7 3.9-1.9.8-1.3.8-2.9-.1-4.6l-41.6-76.1v-.2L281 101.6c.8-1.6.8-3.3.1-4.6-.8-1.2-2.1-1.9-3.9-1.9l-30 .1z"/></svg></a -->
                  </li>
                </ul>
              </div>
              <lazy-image :image="member.acf.team_member.image"></lazy-image>
            </div>
            <figcaption>
              <span class="name">{{ member.title.rendered }}</span>
              <span class="position">{{ member.acf.team_member.position || ' ' }}</span>
              <span class="description" v-html="member.description"></span>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import LazyImage from "@/components/elements/LazyImage.vue";

export default {
  name: 'ExpertsTeaser',
  props: ['data'],
  data() {
    return {}
  },
  components: {LazyImage},
  computed: {
    ...mapState({
      language: state => state.language.language,
      members: state => state.team.members,
    }),
    experts() {
      return this.members?.filter(member => this.data[0].experts.map(m => m.ID).includes(member.id))
    }
  },
  mounted() {
    this.$store.dispatch('team/getAll');
  },
  methods: {
    nl2br(str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
        return '';
      }
      const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
  }
}
</script>

<style lang="scss">
@import '../../styles/config';

.experts {
  h4 {
    font-size: 38px;
    line-height: 47px;
    font-weight: 300;
    margin: 0 0 2em;
    @media screen and (max-width: $medium) {
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $medium) {
      font-size: 25px;
      line-height: 30px;
    }
  }
}
</style>