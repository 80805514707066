

const internHostnames = [
  'localhost',
  'nunatak.com',
  'stage.nunatak.com',
  'www.nunatak.com',
  'admin.nunatak.com',
  'nunatak-admin.buzzinmonkey.com',
  'nunatak.buzzinmonkey.com'
];

const pathTranslations = {
  'pdf': {
    'de': '/pdf/',
    'en': '/en/pdf/'
  },
  'publications': {
    'de': '/themen/',
    'en': '/en/topics/'
  },
  'news': {
    'de': '/news/',
    'en': '/en/news/'
  },
  'post': {
    'de': '/news/',
    'en': '/en/news/'
  },
  'project': {
    'de': '/projekt/',
    'en': '/en/project/'
  },
  'expertises': {
    'de': '/expertises/',
    'en': '/en/expertises/'
  },
  'expertise': {
    'de': '/expertise/',
    'en': '/en/expertise/'
  }
}

export function pathTranslate(key, language = 'de') {
  // eslint-disable-next-line no-console
  if(typeof pathTranslations[key] == 'undefined') {
    return language == 'de' ? '/' : '/en/';
  }
  return pathTranslations[key][language];
}

export function getCoords(elem) { // crossbrowser version
  if(!elem) {
    return { top: 0, left: 0 }
  }
  var box = elem.getBoundingClientRect();

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;

  var top  = box.top +  scrollTop - clientTop;
  var left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}

export function toLink(link) {
  const parser = document.createElement('a');
  parser.href = link;
  // filter intern hostnames
  const matches = internHostnames.filter((hostname) => (new RegExp('^' + hostname, 'gi')).test(parser.hostname))
  if(matches.length || parser.hostname == '') {
    return { link: parser.pathname, type: 'intern' };
  }
  else {
    return { link: link, type: 'extern' };
  }
}