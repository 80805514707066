import { createStore } from 'vuex';
import mouse from './mouse';
import post from './post';
import publication from './publication';
import pdf from './pdf';
import menus from './menus';
import routes from './routes';
import page from './page';
import expertise from './expertise';
import team from './team';
import project from './project';
import category from './category';
import job from './job';
import faq from './faq';
import language from './language';
import options from './options';

export function NKCreateStore() {
  return createStore({
    state: {},
    modules: {
      mouse,
      post,
      publication,
      pdf,
      menus,
      routes,
      page,
      expertise,
      team,
      project,
      category,
      job,
      faq,
      language,
      options
    }
  })
} 