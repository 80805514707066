<template>
  <div class="expertiseBoxesHumanCentric" v-if="expertises && expertises.length">
    <div class="wrapper">
      <h5>{{ data[0].expertise_boxes.title }}</h5>
      <h4 v-html="nl2br(data[0].expertise_boxes.subline)"></h4>
      <div class="expertiseBoxesHumanCentric__container">
        <div class="expertiseBoxesHumanCentric__box" v-for="(expertise, key) in data[0].expertise_boxes.expertises[0].expertise " :key="key">
          <router-link :to="pathTo(expertise)">
            <div class="expertiseBoxesMountainSnapshot">
              <img v-if="key === 0" src="./../../assets/images/mountain-1.png" alt="Nunatak Mountains" />
              <img v-if="key === 1" src="./../../assets/images/mountain-2.png" alt="Nunatak Mountains" />
              <img v-if="key === 2" src="./../../assets/images/mountain-3.png" alt="Nunatak Mountains" />
              <img v-if="key === 3" src="./../../assets/images/mountain-4.png" alt="Nunatak Mountains" />
            </div>
            <span class="number">{{ '0' + (key+1) }}</span>
            <span class="title" v-html="expertise.post_title"></span>
            <span class="label">
              <span class="link-text">{{ $root.translate('more') }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
            </span>
          </router-link>
        </div>

        <div class="expertiseBoxesHumanCentric__humanCentric">
          <div class="expertiseBoxesHumanCentric__humanCentric__innerBox" :class="{'expertiseBoxesHumanCentric__humanCentric__innerBox--show': show }" @click="show = !show">
            <div class="expertiseBoxesHumanCentric__humanCentric__innerBox__icon">
              <svg version="1.1" id="ICON" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 32.9 32.9" xml:space="preserve"><circle class="human-icon" cx="16.5" cy="8.9" r="8.1"/><path class="human-icon" d="M4.5 32.1L7.4 23c.6-1.8 2.3-3 4.2-3h9.8c1.9 0 3.6 1.2 4.2 3l2.9 9.1"/></svg>
            </div>
            <div class="expertiseBoxesHumanCentric__humanCentric__innerBox__content" v-html="data[0].expertise_boxes.human_centric_text">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { pathTranslate } from '../../helpers/NavigationService';
export default {
  name: 'ExpertiseBoxesHumanCentric',
  props: ['data'],
  data() {
    return {
      show: false,
      selectedExpertise: null,
      selectedExpertises: []
    }
  },
  computed: {
    ...mapState({
      expertises: state => state.expertise.all,
      language: state => state.language.language,
    })
  },
  watch: {
    language() {
      this.$store.dispatch('expertise/getAll', { language: this.language });
    },
    expertises() {
      if(
        this.data &&
        this.data[0].expertise_boxes &&
        this.data[0].expertise_boxes.expertises &&
        this.data[0].expertise_boxes.expertises[0].expertise
      ) {
        const expertises = this.data[0].expertise_boxes.expertises[0].expertise.map(({ ID }) => ID);
        this.selectedExpertises = this.expertises.filter((e) => expertises.includes(e.id));
      }
    }
  },
  mounted() {
    this.$store.dispatch('expertise/getAll', { language: this.language });
  },
  methods: {
    nl2br (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
          return '';
      }
      const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    select(expertise, index) {
      expertise.index = index;
      this.selectedExpertise = expertise;
    },
    deselect() {
      setTimeout(() => this.selectedExpertise = null, 1000);
    },
    next() {
      let nextExpertise = this.expertises[0];
      nextExpertise.index = 0;
      this.expertises.forEach((expertise, index) => {
        if(index === this.selectedExpertise.index+1) {
          nextExpertise = expertise;
          nextExpertise.index = index;
        }
      });
      
      this.selectedExpertise = nextExpertise;
    },
    prev() {
      let prevExpertise = this.expertises[this.expertises.length-1];
      prevExpertise.index = this.expertises.length -1;
      this.expertises.forEach((expertise, index) => {
        if(index === this.selectedExpertise.index-1) {
          prevExpertise = expertise;
          prevExpertise.index = index;
        }
      });
      this.selectedExpertise = prevExpertise;
    },
    pathTo(post) {
      if (post.post_type === 'expertise') {
        return pathTranslate('expertises', this.language) + post.post_name;
      }

      if (post.post_type === 'page') {
        return pathTranslate('expertise', this.language) + post.post_name;
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.expertiseBoxesHumanCentric {
  margin-bottom: 130px;
  h4 {
    font-size: 38px;
    line-height: 47px;
    font-weight: 300;
    margin: 0 0 1em;
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $small) {
      font-size: 25px;
      line-height: 30px;
    }
  }
}

.expertiseBoxesHumanCentric__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  @media screen and (max-width: $small) {
    display: block;
  }
  
  .expertiseBoxesHumanCentric__box {
    width: 50%;
    padding: 0 20px 20px 0;
    box-sizing: border-box;
    position: relative;
    @media screen and (max-width: $small) {
      width: 100%;
      padding: 0 0 20px 0;
    }
    
    &:after {
      content: '';
      display: block;
      padding-top: 62%;
      @media screen and (max-width: $small) {
        display: none;
      }
    }
    a {
      position: absolute;
      top: 0;
      bottom: 20px;
      right: 20px;
      left: 0;
      color: white;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background: $primary;
      padding: 30px;
      transition: background-color 500ms ease 100ms;
      @media screen and (max-width: $small) {
        position: relative;
        overflow: hidden;
      }
      .expertiseBoxesMountainSnapshot {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        opacity: 0;
        transition: opacity 500ms ease 100ms;
        background: $color;
        overflow: hidden;
        img,
        canvas {
          position: absolute;
          width: 200%;
          left: 50%;
          bottom: 50%;
          transform: translate(-50%, 50%);  
        }
      }
      
      span {
        display: block;
        position: relative;
        z-index: 10;
        &.number {
          font-size: 17px;
          margin: 0 0 1em;
          font-weight: 300;
          letter-spacing: 0.135em;
          transition: transform 500ms ease;
          @media screen and (max-width: $small) {
            font-size: 8px;
          }
        }
        &.type,
        &.title {
          font-size: 32px;
          line-height: 36px;
          max-width: 75%;
          transition: transform 500ms ease 100ms, color 500ms ease;
          @media screen and (max-width: $medium) { 
            font-size: 28px;
            line-height: 36px;
          }
          @media screen and (max-width: $small) {
            font-size: 25px;
            line-height: 30px;
          }
        }
        &.title {
          margin: 0 0 20px;
          transition: transform 500ms ease 200ms, color 500ms ease;
        }
        &.label {
          display: flex;
          .link-text {
            max-width: 0;
            overflow: hidden;
            transition: max-width 500ms ease;
            word-wrap: none;
            white-space: nowrap;
          }
        }
      }
      svg {
        width: 16px;
        height: 16px;
        transform: rotate(-45deg);
        transition: transform 300ms ease;
        transform-origin: center center;
        > * {
          fill: white;
        }
      }

      &:hover {
        background: $color;
        .expertiseBoxesMountainSnapshot {
          opacity: 1;
        }
        span {
          &.number,
          &.type,
          &.title {
            transform: translate(0,-10px);
            color: white;
            @media screen and (max-width: $small) {
              transform: translate(0,0);
            }
          }
          &.title {
            color: $primary;
          }
          &.label {
            .link-text {
              width: auto;
              max-width: 120px;
            }
          }
        }
        svg {
          transform: rotate(0deg) translate(20px, 2px);
        }
      }
    }
    &:nth-child(3), &:nth-child(4) {
      a {
        justify-content: flex-end;
      }
    }
    span.title {
      color: $color;
    }
  }
}

.human-icon{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}

.expertiseBoxesHumanCentric__humanCentric {
  width: 233px;
  height: 233px;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid $color;
  box-sizing: border-box;
  @media screen and (max-width: $small) {
    right: 0;
    left: auto;
    transform: translate(65%, -50%);
    width: 312px;
    height: 312px;
    z-index: 10;
  }

  &__innerBox {
    min-width: 184px;
    height: 184px;
    background: white;
    position: absolute;
    top: 23px;
    left: 23px;
    border-radius: 92px;
    color: $color;
    max-width: 184px;
    overflow: hidden;
    transition: max-width 400ms ease, background-color 300ms ease, color 300ms ease;
    .human-icon{fill:none;stroke:$color;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}

    @media screen and (max-width: $small) {
      top: 50%;
      right: 50px;
      left: auto;
      transform: translate(0, -50%);
      min-width: 220px;
      height: 220px;
      border-radius: 110px;
      display: flex;
      flex-direction: row-reverse;
    }
    
    &--show {
      background: $color;
      color: white;
      opacity: 1;
      max-width: 600px;
      .human-icon{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}
      .expertiseBoxesHumanCentric__humanCentric__innerBox__icon {
        animation: none;
      }
    }
    @media screen and (min-width: $small) {
    &:hover {
      background: $color;
      color: white;
      opacity: 1;
      max-width: 600px;
      .human-icon{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}
    }
    }
    &__icon {
      width: 90px;
      height: 100px;
      position: absolute;
      top: 52%;
      left: 92px;
      transform: translate(-50%, -50%);
      @media screen and (max-width: $small) {
        width: 50px;
        height: 50px;
        top: 50%;
        right: 130px;
        left: auto;
        animation: bell 1500ms ease infinite;
        @keyframes bell {
          0%, 100% {
            transform: translate(-50%, -50%) rotate(0)
          }
          30% {
            transform: translate(-50%, -50%) rotate(9deg)
          }
          40% {
            transform: translate(-50%, -50%) rotate(-8deg)
          }
          50% {
            transform: translate(-50%, -50%) rotate(7deg)
          }
          60% {
            transform: translate(-50%, -50%) rotate(-6deg)
          }
          70% {
            transform: translate(-50%, -50%) rotate(5deg)
          }
          80% {
            transform: translate(-50%, -50%) rotate(-4deg)
          }
          90% {
            transform: translate(-50%, -50%) rotate(3deg)
          }
          95% {
            transform: translate(-50%, -50%) rotate(-1deg)
          }
        }
      }
    }
    &__content {
      
      min-width: 390px;
      overflow: hidden;
      color: white;
      padding: 15px 30px 0 184px;
      font-size: 19px;
      line-height: 27px;

      @media screen and (max-width: $small) {
        padding: 35px 202px 0 79px;
        font-size: 12px;
        line-height: 18px;
        min-width: 219px;
      }
    }
  }
}
</style>