<template>
<div class="greenTextBlocks">
    <div class="wrapper">
      <h4 v-html="nl2br(data[0].headline)"></h4>
      <div class="greenTextBlocks__container">
        <div class="greenTextBlocks__box" v-for="(textBlock, key) in data[0].text_block" :key="key">
          <div v-html="textBlock.text_box"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'GreenTextBlocks',
  props: ['data'],
  data() {
    return {}
  },
  components: {},
  computed: mapState({
    language: state => state.language.language
  }),
  methods: {
    nl2br (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
          return '';
      }
      const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.greenTextBlocks {
  background: $primary;
  padding: 100px 0 50px;
  width: calc(100% - 80px);
  margin-bottom: 140px;
  .wrapper {
    transform: translate(40px);
  }
  h5 {
    color: white;
  }
  h4 {
    font-size: 38px;
    line-height: 47px;
    font-weight: 300;
    margin: 0 0 1em;
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $medium) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  @media screen and (max-width: $medium) {
    width: 100%;
    .wrapper {
      transform: none;
    }
  }
}

.greenTextBlocks__container {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: $small) {
    display: block;
  }
  
  .greenTextBlocks__box {
    width: 50%;
    box-sizing: border-box;
    position: relative;
    margin: 0 0 2em;
    @media screen and (max-width: $small) {
      width: 100%;
    }

      strong {
        font-weight: 300;
        font-size: 27px;
        line-height: 32px;
      }
      p {
        font-size: 17px;
        line-height: 30px;
        max-width: 460px;
      }

    span.title {
      color: $color;
    }
  }
}
</style>