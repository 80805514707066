<template>
  <div v-if="page && loaded" :class="{academy: page.academyTheme}">
    <dynamic-component v-for="(element, comp) in page.acf" :key="comp" :comp="element.component" :data="element.data" :post="page"></dynamic-component>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DynamicComponent from '../components/elements/DynamicComponent'
import titleMixin from '../mixins/titleMixin';
import metaMixin from '../mixins/metaMixin';

export default {
  name: 'Page',
  mixins: [titleMixin, metaMixin],
  title() {
    return this.page ? 'NUNATAK - ' + this.page.title.rendered : 'NUNATAK';
  },
  meta() {
    if(this.page && this.page.acf) {
      const desc = this.page.acf.find(page => page.component === 'description');
      const keywords = this.page.acf.find(page => page.component === 'keywords');
      const stage = this.page.acf.find(page => page.component === 'stage');
      return {
        description: desc ? desc.data : '',
        keywords: keywords ? keywords.data : '',
        image: stage ? stage.data.header_image.url : null
      }
    }
  },
  data() {
    return {
      loaded: false
    }
  },
  computed: { ...mapState({
      page: state => state.page.page
    }),
    routeSlug() {
      return this.$route.params.slug;
    }
  },
  components: {
    DynamicComponent
  },
  watch: {
    routeSlug() {
      this.init();
    }
  },
  serverPrefetch() {
    return this.init();
  },
  mounted() {
    return this.init();
  },
  asyncData({store, route}) {
    let slug = route.params.slug;
    if( slug == 'en') slug = 'home-2';
    return store.dispatch('page/getPage', {
      slug: slug || 'home',
      language: (route.path.indexOf('/en') === 0) ? 'en' : 'de'
    })
  },
  methods: {
    init() {
      this.loaded = false;
      let slug = this.$route.params.slug;
      console.log(slug);
      if( slug == 'en') slug = 'home-2';
      return new Promise((resolve) => {
        this.$store.dispatch('routes/getRoutes', this.$route.path.indexOf('/en') === 0 ? 'en' : 'de').then( () => {
          this.$store.dispatch('page/getPage', {
            slug: slug || 'home',
            language: (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de'
          }).then(() => {
            if(this.page) {
              
              Promise.all([
                this.$store.dispatch('language/setCurrentLanguage', this.page.lang),
                this.$store.dispatch('routes/setOppositePage', this.page.translations[(this.page.lang == 'en') ? 'de' : 'en'])
              ]).finally(() => {
                this.loaded = true;
                resolve()
                this.$root.loading = false;
              })
            }
            else {
              // eslint-disable-next-line no-console
              this.$store.dispatch('language/setCurrentLanguage', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de');
              this.$router.replace(((this.$route.path.indexOf('/en') === 0) ? '/en' : '') + '/404');
              resolve();
            }
            
          }).catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
            this.$store.dispatch('language/setCurrentLanguage', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de');
            this.$router.replace(((this.$route.path.indexOf('/en') === 0) ? '/en' : '') + '/404');
            resolve()
          });
        })
      }) 
    }
  }
}
</script>