<template>
  <div>
    <component v-if="componentName != 'Description' && componentName != 'Keywords'" :is="componentName" :data="data" :post="post" />
  </div>
</template>

<script>
/* eslint-disable */ 
import IntroText from './IntroText';
import ContentContainer from './ContentContainer';
import Stage from './Stage';
import RelatedPublications from './RelatedPublications';
import DownloadRequest from './DownloadRequest';
import ExpertiseBoxes from './ExpertiseBoxes';
import ExpertiseBoxesHumanCentric from './ExpertiseBoxesHumanCentric';
import ExpertiseBoxesAlt from './ExpertiseBoxesAlt';
import ClientLogos from './ClientLogos';
import CatchLine from './CatchLine';
import Facts from './Facts';
import TextSplit from './TextSplit';
import Team from './Team';
import OurValues from './OurValues';
import AdvisoryBoard from './AdvisoryBoard';
import ContactTeaser from './ContactTeaser';
import AcademyTeaser from './AcademyTeaser';
import NewsSlider from './NewsSlider';
import GreatTeaser from './GreatTeaser';
import Intro from './Intro';
import CareerGallery from './CareerGallery';
import TeamTeaser from './TeamTeaser';
import OfferBoxes from './OfferBoxes';
import Slider from './Slider';
import Steps from './Steps';
import Jobs from './Jobs';
import Faqs from './Faqs';
import Newsletter from './Newsletter';
import MountainStage from './MountainStage';
import ProjectReference from './ProjectReference';
import RelatedNews from './RelatedNews';
import AcademyTriangle from './AcademyTriangle';
import AcademyBoxes from './AcademyBoxes';
import Image from './Image';
import ExpertiseStage from "./ExpertiseStage.vue";
import GreenTextBlocks from "./GreenTextBlocks.vue";
import TopicTeasers from "./TopicTeasers.vue";
import ExpertsTeaser from "./ExpertsTeaser.vue";

export default {
  name: 'DynamicComponent',
  props: ['comp', 'data', 'post'],
  components: {
    Stage,
    Intro,
    IntroText,
    ContentContainer,
    RelatedPublications,
    DownloadRequest,
    ExpertiseStage,
    ExpertiseBoxes,
    ExpertiseBoxesHumanCentric,
    ExpertiseBoxesAlt,
    ClientLogos,
    CatchLine,
    Facts,
    TextSplit,
    Team,
    AdvisoryBoard,
    OurValues,
    ContactTeaser,
    AcademyTeaser,
    NewsSlider,
    GreatTeaser,
    CareerGallery,
    TeamTeaser,
    OfferBoxes,
    Slider,
    Steps,
    Jobs,
    Faqs,
    Newsletter,
    MountainStage,
    ProjectReference,
    RelatedNews,
    AcademyTriangle,
    AcademyBoxes,
    Image,
    GreenTextBlocks,
    TopicTeasers,
    ExpertsTeaser
  },
  computed: {
    componentName() {
      return this.comp.split('_').map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join('');
    }
  }
}
</script>