import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    options: {}
  },
  mutations: {
    setOptions(state, {options}) {
      state.options = options;
    }
  },
  actions: {
    getOptions({ commit }) {
      (new APIService).getOptions().then( (options) => {
        commit('setOptions',{
          options: options.data.acf
        });
      })
    }
  }
}