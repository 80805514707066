<template>
<div class="wrapper">
    <div class="intro">
      <div class="introHeadline">
        <h5>{{ data.title }}</h5>
        <h3>{{ data.subline }}</h3>
        <div class="image" v-if="data.image.url">
          <lazy-image :image="data.image"></lazy-image>
        </div>
      </div>
      <div class="introContent">
        <div v-html="data.content"></div>
        <div class="introLinks" v-if="data.link.url">
          <a :href="data.link.url" @click="pathTo(data.link.url, $event)" class="arrowLink">
            {{ data.link.title }}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
          </a>
        </div>
        <div class="image" v-if="data.image.url">
          <lazy-image :image="data.image"></lazy-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toLink } from '../../helpers/NavigationService';
import LazyImage from './LazyImage';
export default {
  name: 'Intro',
  props: ['data'],
  components: {
    LazyImage
  },
  methods: {
    pathTo(path, $event) {
       $event.preventDefault();
      if(path.indexOf('#') === 0) {
        const moduleTop = document.querySelector(path) ? document.querySelector(path).offsetTop : 0;
        window.scrollTo(0, moduleTop)
        return true;
      }
     
      const link = toLink(path);
      if(link.type === 'intern') {
        this.$router.push(link.link);
      }
      else {
        window.open(link.link);
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/index';
.intro {
  display: flex;
  margin-bottom: 165px;
  @media screen and (max-width: $small) {
    display: block;
    margin-bottom: 100px;
  }
  &Headline {
    width: 37%;
    flex: 1;
    @media screen and (max-width: $small) {
      width: auto;
      margin-bottom: 1em;
    }
    h5 {
      margin: 0 0 0.5em;
    }
    h3 {
      font-size: 38px;
      line-height: 47px;
      font-weight: 300;
      margin: 0 0 40px;
      @media screen and (max-width: $medium) { 
        font-size: 30px;
        line-height: 38px;
      }
      @media screen and (max-width: $small) { 
        font-size: 25px;
        line-height: 30px;
      }
    }
    div.image {
      width: 85%;
      position: relative;
      overflow: hidden;
      @media screen and (max-width: $small) {
        display: none;
      }
      &:after {
        content: '';
        display: block;
        padding-top: 90%;
      }
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        
      }
    }
  }
  &Content {
    width: 63%;
    box-sizing: border-box;
    padding-left: 50px;
    font-size: 17px;
    line-height: 30px;
    display: block;
    @media screen and (max-width: $small) {
      width: auto;
      padding-left: 0;
    }
    @media screen and (max-width: $small) {
      font-size: 15px;
      line-height: 24px;
    }
    p {
      margin: -4px 0 1.5em;
    }
    .introLinks {
      a {
        font-size: 15px;
        line-height: 1.2em;
        @media screen and (max-width: $medium) {
          font-size: 15px;
          line-height: 24px;
          margin-bottom: 30px;
        }
      }
    }

    div.image {
      width: 100%;
      img {
        width: 100%;
        display: none;
        @media screen and (max-width: $small) {
          display: block;
        }
      }
    }
  }
}
</style>