<template>
  <div ref="root" class="videoLayer" v-if="youtubeId" :id="'yt-' + youtubeId" :class="{ show: show }">
    <a href="" @click.prevent="$emit('close')" class="close">
      <span></span>
    </a>
    <div v-if="allowed" :id="'youtubePlayer' + random"></div>
    <div v-if="!allowed" class="privacyHint">
      {{ $root.translate('privacy_hint')}} &nbsp;
      <a href="" class="arrowLink" @click.prevent="cookieOpen()">
        {{ $root.translate('privacy_change') }}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
      </a>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
export default {
  name: 'VideoLayer',
  props: ['youtubeId', 'show'],
  setup() {
      const root = ref(null)
      return {
        root
      }
    },
  watch: {
    show(value) {
      if(value && this.player && this.player.playVideo) {
        this.player.playVideo();
      }
      else {
        setTimeout(() => this.player.stopVideo(), 500);
      }
    }
  },
  data() {
    return {
      allowed: window?.Cookiebot?.consent?.marketing,
      player: null,
      random: Math.round(Math.random() * 1000)
    }
  },
  beforeUnmount() {
    if(this.$refs.root) {
      document.body.removeChild(this.$refs.root)
    }
  },
  mounted() {
    window.addEventListener('CookiebotOnAccept', () => {
      if (!window.Cookiebot.consent.marketing) return;
      this.youtubeAPIReady().then(() => {
        this.setVideo();
      });
      this.allowed = window.Cookiebot.consent.marketing;
    }, false);
    this.$nextTick(() => {
        if(this.$refs.root && this.$refs.root?.parentNode) {
          //this.$el.parentNode.removeChild(this.$el);
          document.body.appendChild(this.$refs.root);
        }
        if(window?.Cookiebot?.consent?.marketing) {
          this.youtubeAPIReady().then(() => {
            this.setVideo();
            
          });
          this.allowed = window?.Cookiebot?.consent?.marketing;
        }
        else {
          this.allowed = window?.Cookiebot?.consent?.marketing;
        }

    })
  },
  methods: {
    cookieOpen() {
      window?.Cookiebot?.show();
    },
    youtubeAPIReady() {
      return new Promise((resolve) => {
        let interval = setInterval(() => {
          if(window.YouTubeAPIReady) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      })
    },
    setVideo() {
      // eslint-disable-next-line
      this.player = new YT.Player('youtubePlayer' + this.random, {
        height: '360',
        width: '640',
        videoId: this.youtubeId,
      })
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.videoLayer {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0ms ease 1000ms, opacity 500ms ease;
  .close {
    display: block;
    width: 64px;
    height: 64px;
    background: $color;
    border-radius: 50%;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      &:after,
      &:before {
        content: '';
        display: block;
        width: 60%;
        height: 2px;
        background: white;
        transform: translate(-50%, -50%) rotate(45deg);
        position: absolute;
        top: 50%;
        left: 50%;
      }
      &:after {
        content: '';
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
  iframe {
    width: 100%;
    height: 100%;
  }
  &.show {
    pointer-events: initial;
    visibility: visible;
    opacity: 1;
    transition: visibility 10ms ease 0ms, opacity 500ms ease;
  }
  .privacyHint {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>