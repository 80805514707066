<template>
  <div class="wrapper topicTeasers">
    <h4 v-html="(data[0].headline)"></h4>
    <div class="topicTeasersContainer">
      <div class="item" v-for="(item) in selectedPublication" :key="item.id">
        <figure>
          <router-link :to="(language == 'en' ? '/en/topics' : '/themen') + `/${item.slug}`">
            <div>
              <lazy-image :refreshCount="refreshCount" :image="item.acf[0].data"></lazy-image>
            </div>
          </router-link>
          <figcaption>
            <div v-if="isWhitepaper(item)" class="whitepaperIcon">
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="Ebene_1" x="0" y="0"
                   style="enable-background:new 0 0 64 64" version="1.1" viewBox="0 0 64 64"><path d="M50 18v36H14V10h28z" style="fill:none;stroke:#000;stroke-width:2.126;stroke-linecap:square" />
                <path d="M40.8 10v9.2H50M32 38.8V22M38 34l-6 6-6-6M42 40v6H22v-6"
                      style="fill:none;stroke:#000;stroke-width:2.126;stroke-linecap:square"/></svg>
            </div>
            <div>
              <span class="date">{{ formatDate(item.date) }}</span>
              <router-link :to="(language == 'en' ? '/en/topics' : '/themen') + `/${item.slug}`"><span class="title"
                                                                                                       v-html="item.title.rendered"></span>
              </router-link>
            </div>
            <router-link :to="(language == 'en' ? '/en/topics' : '/themen') + `/${item.slug}`" class="arrowLink">
              {{ $root.translate('to_publication') }}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4">
                <path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/>
              </svg>
            </router-link>
          </figcaption>
        </figure>
      </div>
    </div>
  </div>

</template>

<script>
import {toLink} from '../../helpers/NavigationService';
import LazyImage from './LazyImage';
import {mapState} from "vuex";

export default {
  name: 'Intro',
  props: ['data'],
  components: {
    LazyImage
  },
  computed: {
    ...mapState({
      publications: state => state.publication.posts,
      language: state => state.language.language
    }),
    selectedPublication() {
      console.log(this.publications);
      return this.publications?.filter(post => this.data[0].topics.map(t => t.ID).includes(post.id)) || [];
    },
  },
  mounted() {
    this.$store.dispatch('publication/getPublications', {language: this.language});

  },
  methods: {
    isWhitepaper(post) {
      return post['publication-categories'].includes(1029) || post['publication-categories'].includes(1037)
    },
    formatDate(date) {
      let d = new Date(Date.parse(date));
      return ('0' + d.getDate()).slice(-2) + '.' + ('0' + (d.getMonth() + 1)).slice(-2) + '.' + d.getFullYear();
    },
    pathTo(path, $event) {
      $event.preventDefault();
      if (path.indexOf('#') === 0) {
        const moduleTop = document.querySelector(path) ? document.querySelector(path).offsetTop : 0;
        window.scrollTo(0, moduleTop)
        return true;
      }

      const link = toLink(path);
      if (link.type === 'intern') {
        this.$router.push(link.link);
      } else {
        window.open(link.link);
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/index';

.topicTeasers {
  h4 {
    font-size: 38px;
    line-height: 47px;
    font-weight: 300;
    margin: 0 0 2em;
    @media screen and (max-width: $medium) {
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $medium) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  .topicTeasersContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 130px;
    @media screen and (max-width: $small) {
      margin-bottom: 100px;
    }

    .furtherButton {
      display: block;
      min-width: 100%;
    }

    .furtherEntries {
      flex-wrap: wrap;
      display: none;

      &.show {
        display: flex;
      }
    }

    .item {
      width: 33.33%;
      margin-bottom: 100px;
      @media screen and (max-width: $small) {
        width: 100%;
        margin-bottom: 50px;
      }

      a {
        text-decoration: none;
      }

      figure {
        margin: 0;
        padding: 0;
        display: flex;
        min-height: 100%;
        flex-direction: column;

        .ie & {
          display: block;
        }

        > a > div {
          position: relative;
          overflow: hidden;
          margin: 0 20px 0 0;
          background: $primary;
          @media screen and (max-width: $small) {
            margin: 0;
          }

          img {
            position: absolute;
            top: 0;
            left: 50%;
            transition: transform 500ms ease;
            transform: translate(-50%) scale(1);
            height: 100%;
          }

          &:after {
            content: '';
            display: block;
            padding-top: 56.5%;
          }
        }

        figcaption {
          position: relative;
          text-decoration: none;
          color: $color;
          flex: 1;
          //min-height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .whitepaperIcon {
            position: absolute;
            top: 0px;
            transform: translate(0, -50%);
            right: 30px;
            width: 76px;
            height: 76px;
            border-radius: 50%;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 50px;
            }
          }

          .date {
            letter-spacing: 0.135em;
            font-size: 12px;
            text-transform: uppercase;
            display: block;
            margin: 8px 0 15px;

            .category {
              color: $primary;
              display: inline-block;
              //margin-left: 20px;
              a {
                display: inline-block;
                margin: 0 5px;
              }
            }
          }

          .title {
            font-size: 27px;
            line-height: 35px;
            display: block;
            margin-bottom: 15px;
            max-width: 90%;
            color: $color;
            //min-height: 200px;
            @media screen and (max-width: $small) {
              font-size: 20px;
              line-height: 25px;
            }
          }
        }
      }

      &:hover {
        figure {
          > a > div {
            img {
              transform: translate(-50%) scale(1.1);
            }
          }
        }
      }
    }
  }
}

</style>